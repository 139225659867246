import React from "react"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import { AiFillYoutube } from "react-icons/ai"
import { MdClose } from "react-icons/md"

import getYouTubeId from "get-youtube-id"
import YouTube from "react-youtube"
const urlFor = source =>
  imageUrlBuilder({ projectId: "ppd47c0u", dataset: "production" })
    .image(source)
    .width(900)

const serializers = {
  types: {
    figure: props => (
      <figure className="animation--fade-up animation">
        {props.node.image && (
          <img src={urlFor(props.node.image.asset)} alt={props.node.alt} />
        )}
        <div className="figure_text">
          <h2>{props.node.title}</h2>
          <p>{props.node.description}</p>
        </div>
      </figure>
    ),

    video: props => (
      <div
        key={props.node.id}
        className="iframe_wrapper"
        dangerouslySetInnerHTML={{ __html: `${props.node.iframe}` }}
      />
    ),
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    videoContainer: props => (
      <figure>
        <div
          className="iframeContainer"
          onClick={openVideoContainerByClickingOnIframe}
          id={`video${props.node._key}`}
        >
          <MdClose
            className="closeIframeContainer"
            onClick={closeIframeContainer}
          />
          {getIframeContent(props.node.video)}
        </div>
        {props.node.image && (
          <div className="videoBlockImageContainer">
            <img
              src={urlFor(props.node.image.asset)}
              alt={props.node.alt}
              onClick={openVideoContainer}
            />
            <AiFillYoutube />
            <div className="iconFillAiFillYoutube"></div>
            <div className="videoContainerTitle">
              <p className="employee">{props.node.employee}</p>
              <p className="position">{props.node.Position}</p>
            </div>
          </div>
        )}
        <div className="figure_text">
          <h2>{props.node.title}</h2>
          <BlockContent
            blocks={props.node.description}
            projectId="ppd47c0u"
            dataset="production"
            serializers={serializers}
            className="newBlockType"
          />
        </div>
      </figure>
    ),

    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW
    // JKWENDKWEJFNEWKFNFEWKJNFKJWEJNFWKEJFW

    files: props => (
      <a
        href={`${props.node.file.asset._ref}`}
        id={props.node.file.asset._ref}
        onClick={changeURl}
      >
        <p className="file" id={props.node.file.asset._ref}>
          {" "}
          {props.node.title}
        </p>
      </a>
    ),
  },
}

function openVideoContainer(e) {
  e.target.parentNode.parentNode
    .querySelector(".iframeContainer")
    .classList.add("showIframeContainer")
}

function openVideoContainerByClickingOnIframe(e) {
  e.target.classList.remove("showIframeContainer")
}
function closeIframeContainer(e) {
  e.target.parentNode.classList.remove("showIframeContainer")
}

export default serializers
function changeURl(e) {
  e.preventDefault()

  let str = e.target.id
  let shortURL = str.slice(5)
  let newURL = shortURL.replace("-", ".")
  let extendedURL = "https://cdn.sanity.io/files/ppd47c0u/production/" + newURL

  window.location.href = extendedURL
}

function getIframeContent(url) {
  // const { url } = node
  const id = getYouTubeId(url)
  return <YouTube videoId={id} />
}
